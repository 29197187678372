.page-content {
  font-family: Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
  display: flex;
  flex-direction: column;
  margin-left: 75px;
  background-color: #f4f9f4;
  align-items: center;
  overflow-y: auto; /* Add vertical scroll */
  max-height: calc(100vh - 20px); /* Limit the height of the page to the viewport height minus top margin */
}

.search-box {
  margin-top: 20px; /* Add top margin */
}

.table-container {
  margin: 20px auto;
  display: flex;
  max-height: 630px;
  font-size: 16px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  overflow-x: auto; /* Add horizontal scroll */
  color: purple;
  font-weight: 200;
  border: 1px solid #ddd;
  background-color: #fff;
  padding: 20px; /* Add padding to avoid table touching the container borders */
  border-radius: 10px; /* Round the corners of the table container */
}

.table {
  min-width:min-content;
  overflow-x:auto;
  border-collapse: separate; /* Use separate to apply spacing between rows */
  background-color: #fff;
  border-spacing: 0 12px; /* Space between rows */
}

.table thead th {
  border-bottom: 2px solid #ddd;
  text-align: left;
  border: none;
  padding-left: 10px;
  padding-top: 0px;
  padding-bottom: 0px;
  background-color: #fff;
  
  color: purple !important;
}

.table tbody tr {
  border-radius: 10px; /* Round the corners of the rows */
  background-color: #fff;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1); /* Add a slight shadow for better visibility */
  
  color: purple !important;
}

.table tbody tr td {
  border: none; /* Remove default border from cells */
  padding: 10px;
  
  color: purple !important;
}

.th-td {
  padding: 8px;
  margin-top: 0px;
  text-align: left;
  
  color: purple !important;
}

.pagination {
  display: flex;
  justify-content: center;
}

.button {
  margin: 0 5px;
  padding: 10px 20px;
  border: none;
  border-radius: 10px;
  background-color: #674F7F;
  color: #fff;
  cursor: pointer;
}

.action-cell {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 18px; /* Add gap between icons */
}

.action-cell i {
  cursor: pointer;
}

.icon-purple-dark {
  color: #6a0dad; /* Dark purple color */
}

.icon-purple-dark:hover {
  color: #4b0082; /* Even darker shade on hover */
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 10px; /* Adjust margin as needed */
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #674F7F;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.toggle-container {
  display: flex;
  align-items: center;
  margin-left: 10px; /* Adjust margin as needed */
}

.toggle-label {
  margin-right: 10px; /* Adjust margin as needed */
  font-size: 18px;
  font-weight: bolder;
  color: #6a0dad;
}

.toggle-text {
  margin-left: 10px;
  font-size: 16px;
  font-weight: bolder;
  vertical-align: middle;
  color: #6a0dad;
}

.search-box {
  margin-top: 15px; /* Add top margin */
  position: relative; /* Set position to relative for absolute positioning of the icon */
  display: flex;
  align-items: center; /* Align items center vertically */
}

.search-input {
  padding-left: 20px; /* Adjust padding to accommodate the icon */
  height: 40px;
  width: 500px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 15px;
}

.search-icon {
  position: absolute; /* Position the icon absolutely */
  top: 50%; /* Center vertically */
 /* Adjust left position */
  transform: translateY(-50%); /* Center vertically */
  cursor: pointer;
  right: 270px;
  width: 22px;
 
  color:  #674F7F;/* Make it round */
}
