/* ExcelUploader.css */


  .upload-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
  }
  
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100px; /* Adjust height as needed */
    background-color: #674F7F;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
  }
  
  .header img {
    width: 15%; /* Set the width to 100% of its container */
    height: auto; /* Maintain aspect ratio */
    max-width: none; /* Allow the image to exceed its natural width */
    object-fit: cover;
    margin-top: 500px;
  }
  
  .header-text {
    font-size: 2em;
    margin-top: 20px;
    color: black;
    align-items: center;
  }
  
  .inputfile {
    width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
  }
  
  .inputfile-6 + label {
    border: 1px solid #3950d3;
    background-color: #f1e5e6;
    padding: 0;
    display: inline-block; 
    margin-top: 430px;/* Ensure the label behaves correctly */
  }
  
  .inputfile-6 + label span {
    width: 200px;
    min-height: 2em;
    display: inline-block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    vertical-align: top;
  }
  
  .inputfile-6 + label span,
  .inputfile-6 + label strong {
    padding: 0.625rem 1.25rem;
  }
  
  .inputfile-6 + label strong {
    height: 100%;
    color: #f1e5e6;
    background-color: #3950d3;
    display: inline-block;
  }
  
  .inputfile + label svg {
    width: 1em;
    height: 1em;
    vertical-align: middle;
    fill: currentColor;
    margin-top: -0.25em;
    margin-right: 0.25em;
  }
  
  .upload-button {
    margin-top: 20px;
    margin-left: 90px;
    background-color: #007bff;
    color: white;
    border: none;
    padding: 15px 30px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    align-items: center;
    text-transform: uppercase;
    outline: none;
    transition: background-color 0.3s ease, transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .upload-button:hover {
    background-color: #0056b3;
  }
  
  .footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px; /* Adjust height as needed */
    background-color: #674F7F;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2em;
    font-weight: bold;
    text-transform: uppercase;
    box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.2);
  }
  