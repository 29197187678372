.container {
  display: flex;
  flex-direction: row; /* Keep as row for larger screens */
  height: 100%;
  min-height: 100vh;
  width: 100%;
  background: linear-gradient(135deg, #5E376C, #954C93);
}

.leftSection {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Adjust padding as needed */
  order: 1; /* Ensure leftSection is on the left */
}

.leftSection img {
  width: 110%; /* Set the width to 100% of its container */
  height: auto; /* Maintain aspect ratio */
  max-width: none; /* Allow the image to exceed its natural width */
  object-fit: cover; /* Scale the image while maintaining aspect ratio */
}

.rightSection {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px; /* Adjust padding as needed */
  order: 2; /* Ensure rightSection is on the right */
}

.innerBox {
  width: fit-content;
  max-width: 480px;
  padding: 20px; /* Reduced padding */
  background-color: rgb(255, 255, 255);
  border-radius: 10px;
  box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  gap: 10px; /* Reduced gap */
  max-height: 95vh; /* Prevent full page coverage */
}

.logo {
  text-align: center;
}

.logo img {
  width: 120px; /* Reduced size */
  height: 120px; /* Reduced size */
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid #6d6565;
  box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.6);
}

.logo h6 {
  color: #636363;
  font-size: 14px; /* Reduced font size */
  text-align: center;
  font-weight: 600;
  padding-top: 15px; /* Reduced padding */
 
}

.blinkingMessage {
  color: red;
  font-weight: bold;
  animation: blink-animation 1s infinite;
}

@keyframes blink-animation {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


.heading {
  color: #737373;
  text-align: center;
  margin: 1px;

}

.footer {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 10px; /* Reduced gap */
}

.footer .error {
  font-weight: bold;
  font-size: 0.875rem;
  text-align: center;
}

.footer button {
  outline: none;
  border: none;
  background-color: #674f7f;
  box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.7);
  color: #fff;
  border-radius: 5px;
  font-weight: bold;
  font-size: 0.9rem; /* Reduced font size */
  padding: 8px 10px; /* Reduced padding */
  width: 100%;
  transition: 100ms;
  cursor: pointer;
}

.footer button:disabled {
  background-color: gray !important;
}

.footer button:hover {
  background-color: #443157;
}

.footer p {
  font-weight: 700;
  color: #675080;
  margin-bottom: 0px;
  margin-top: 0px;
}

.footer p span a {
  font-weight: bold;
  color: #737373;
  letter-spacing: 1px;
  font-size: 0.9rem; /* Reduced font size */
  text-decoration: none;
}

/* Media Queries for Tablet */
@media only screen and (max-width: 768px) {
  .container {
    flex-direction: column; /* Stack vertically on smaller screens */
  }

  .leftSection {
    order: 1; /* Move sideImage below innerBox */
    padding-bottom: 20px; /* Add padding to bottom of leftSection */
  }

  .rightSection {
    order: 2; /* Move innerBox above sideImage */
  }

  .innerBox {
    min-width: 300px;
    padding: 15px; /* Reduced padding */
  }

  .logo img {
    width: 80px; /* Reduced size */
    height: 80px; /* Reduced size */
  }

  .logo h6 {
    font-size: 10px; /* Reduced font size */
  }

  .footer button {
    padding: 6px 8px; /* Reduced padding */
    font-size: 0.8rem; /* Reduced font size */
  }
}

/* Media Queries for Mobile */
@media only screen and (max-width: 480px) {
  .leftSection {
    order: 1; /* Move sideImage below innerBox */
    padding-bottom: 20px; /* Add padding to bottom of leftSection */
  }

  .rightSection {
    order: 2; /* Move innerBox above sideImage */
  }

  .innerBox {
    min-width: 250px;
    padding: 10px; /* Reduced padding */
  }

  .logo img {
    width: 60px; /* Reduced size */
    height: 60px; /* Reduced size */
  }

  .logo h6 {
    font-size: 8px; /* Reduced font size */
  }

  .footer button {
    padding: 4px 6px; /* Reduced padding */
    font-size: 0.7rem; /* Reduced font size */
  }
}
